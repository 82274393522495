<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <b-form-group label="Upload Images">
              <b-media no-body>
                <b-media-aside>
                  <b-img rounded :src="getApiFile(null, true)" height="80"/>
                </b-media-aside>
                <b-media-body class="mt-75 ml-75">
                  <b-button variant="primary" size="sm" class="mb-75 mr-75" @click="selectFile()">Upload</b-button>
                  <b-form-file
                      plain
                      multiple
                      :hidden="true"
                      id="image_file"
                      name="image_file"
                      accept=".jpg, .png, .gif"
                      @input="inputImageRenderer()"/>
                  <b-card-text>Allowed JPG, GIF or PNG.</b-card-text>
                </b-media-body>
              </b-media>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Language">
              <b-form-group label="Language" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.languageId" :options="languageOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-row>
      <b-table
          ref="refListTable"
          primary-key="id"
          empty-text="No record found"
          class="position-relative"
          :items="fetchList"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          responsive striped bordered hover show-empty>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <remove-button-table :data="data" :re-fetch-data="reFetchData" remove-url="removeImage"></remove-button-table>
          </div>
        </template>

        <template #cell(image_type)="data">
          <b-badge pill :variant="`light-primary`" class="text-capitalize">
            Image
          </b-badge>
        </template>

        <template #cell(image_url)="data">
          <b-avatar
              :src="getApiFile(data.value)"
              :text="avatarText(data.item.name + ' ' + data.item.lastname)"
              :variant="`light-primary`"
              size="30px"/>
          <span>&#160;{{ data.value }}</span>
        </template>

      </b-table>
    </b-row>

    <Overlay :busy="busy"></Overlay>

  </b-card>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import Overlay from "@/components/Overlay.vue";
import router from '@/router'
import store from "@/store"
import storeModule from "@/views/cms/content/store"
import {email, required} from "@core/utils/validations/validations";
import {toastMessage} from "@core/utils/utils"
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from "vue-toastification/composition";
import {BAvatar, BBadge, BButton, BCard, BCardText, BCol, BForm, BFormFile, BFormGroup, BFormInvalidFeedback, BImg, BMedia, BMediaAside, BMediaBody, BRow, BTable} from "bootstrap-vue";
import {avatarText, getApiFile} from "@core/utils/filter";
import vSelect from 'vue-select'
import languageModule from "@/views/cms/language/store";
import IdColumnTable from "@/components/Table/IdColumnTable.vue";
import RemoveButtonTable from "@/components/Table/RemoveButtonTable.vue";
import useList from "@/views/cms/content/useImageList";

export default {
  components: {
    BAvatar,
    BBadge,
    RemoveButtonTable,
    IdColumnTable,
    BTable,
    BFormInvalidFeedback,
    BImg,
    BMediaAside,
    BCardText,
    BFormFile,
    BMediaBody,
    BMedia,
    BFormGroup,
    BCol,
    BRow,
    BCard,
    BForm,
    BButton,

    vSelect,
    Overlay,
    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_LANGUAGE_MODULE_NAME = 'store-language'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_LANGUAGE_MODULE_NAME, languageModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_LANGUAGE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const dataInfo = ref({contentId: router.currentRoute.params.id, languageId: 0, images: null})
    const languageOptions = ref([])

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,

      fetchList,
      reFetchData,
    } = useList()

    const onSubmit = (images) => {
      const formData = new FormData();
      for (var i = 0; i < images.length; i++) {
        let file = images[i];
        formData.append('files[' + i + ']', file);
      }

      formData.append('content_id', dataInfo.value.contentId);
      formData.append('language_id', dataInfo.value.languageId);

      busy.value = true
      store.dispatch('store/addImages', formData).then(response => {
        toastMessage(toast, 'success', response.data.message)
        reFetchData()
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
      }).finally(message => {
        busy.value = false
      })

    };
    const selectFile = () => {
      document.getElementById('image_file').click()
    }
    const resetFile = () => {
      dataInfo.images = null
    }
    const inputImageRenderer = () => {
      onSubmit(document.getElementById('image_file').files)
    }

    const {refFormObserver, getValidationState, resetForm} = formValidation()

    busy.value = true
    store.dispatch('store-language/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.panel_status === 1) {
          languageOptions.value.push({label: value.name, value: value.id})
          if (value.main_language === true) {
            dataInfo.value.languageId = value.id
          }
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
      if (error.response.status === 403) {
        router.push({name: 'cms-content-list'})
      }
    }).finally(message => {
      busy.value = false
    })

    return {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,

      busy,
      refFormObserver,

      dataInfo,
      languageOptions,

      onSubmit,
      selectFile,
      resetFile,
      inputImageRenderer,
      getValidationState,
      resetForm,
      getApiFile,
      fetchList,
      reFetchData,
      avatarText,
    };
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
